const DEFAULT_MOBILE_MENU_ID = 'main-mobile-menu';

class GoodBedHeader {
  constructor() {
    this.animationDuration = 400;
    this.headerContent = $('#header-content');
    this.accountContent = $('.header-account-content');
    this.headerMenu = $('.header-menu');
    this.translucentOverlayDesktop = $('.header-desktop-translucent-overlay');
    this.translucentOverlayMobile = $('.header-mobile-translucent-overlay');
    this.currentMobileSideBarActiveMenuID = DEFAULT_MOBILE_MENU_ID;
  }

  setUpStickyHeader() {
    const { headerMenu } = this;
    function scrollUpListener(header) {
      let lastScrollTop = 0;

      return () => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        if (scrollTop > lastScrollTop && scrollTop > headerMenu.outerHeight()) {
          header.addClass('hide');
        } else {
          header.removeClass('hide');
        }

        lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
      };
    }

    if (window.DISABLE_STICKY_HEADER) {
      headerMenu.removeClass('fixed-top');
    } else {
      window.addEventListener('scroll', scrollUpListener($('.header-container')), {
        capture: true,
        passive: true,
      });
    }
  }

  handleClickOutside() {
    const { accountContent, headerContent, animationDuration, translucentOverlayDesktop, translucentOverlayMobile } =
      this;
    const mobileSearch = $('.mobile-search-container__panel');
    const mobileMenu = $('.menu-container');
    const content = headerContent.find('.header-menu-content__white_background');
    const objReference = this;

    $('html').on('click', function clickOutside() {
      if (headerContent.is(':visible') && !content.is(':hover')) {
        headerContent.slideUp(animationDuration, () => {
          translucentOverlayDesktop.hide();
        });
        $('#header-desktop .header-container').removeClass('open');
        $('.header-menu__item.active').removeClass('active');
        $('.header-menu-content__item').fadeOut(animationDuration);
      }
      if (accountContent.is(':visible') && !accountContent.is(':hover')) {
        $('.header-menu__item.active').removeClass('active');
        $('.header-menu-content__item').hide();
        accountContent.fadeOut(animationDuration);
      }
      if (mobileSearch.is(':visible') && !mobileSearch.is(':hover')) {
        $('#search-btn').removeClass('active');
        $('#search-container').animate({ width: 'hide' }, animationDuration, 'linear', () => {
          translucentOverlayMobile.hide();
        });
      }
      if (mobileMenu.is(':visible') && !mobileMenu.is(':hover')) {
        $('#mobile-side-menu').animate({ width: 'hide' }, animationDuration, 'linear', () => {
          $('.menu-container__sublist').hide();
          $('#main-mobile-menu').show();
          $('#mobile-side-menu').removeClass('open');
          translucentOverlayMobile.hide();

          // Reset mobile active menu.
          objReference.currentMobileSideBarActiveMenuID = DEFAULT_MOBILE_MENU_ID;
        });
      }
    });
  }

  showHeaderMenuContent() {
    const { accountContent, animationDuration, translucentOverlayDesktop, headerContent } = this;
    const menuItems = $('.header-menu__item.has-children');
    menuItems.on('click', function menuClick(e) {
      e.preventDefault();
      e.stopPropagation();

      const el = $(this);
      const contendId = el.attr('data-content-id');
      const contentEl = $(`#${contendId}`);
      const isSearch = contendId === 'header-content__search';

      const isActive = el.hasClass('active');
      accountContent.fadeOut(animationDuration);
      $('.header-menu-content__item').hide();
      $('.header-menu__item.active').removeClass('active');

      if (isActive) {
        return headerContent.slideUp(animationDuration, () => {
          el.removeClass('active');
          $('#header-desktop .header-container').removeClass('open');
          translucentOverlayDesktop.hide();
        });
      }

      $('#header-desktop .header-container').addClass('open');
      el.addClass('active');
      contentEl.fadeIn(animationDuration);
      translucentOverlayDesktop.show();
      return headerContent.slideDown(animationDuration, () => {
        if (isSearch) {
          $('#header-content__search .search-text').focus();
        }
      });
    });
  }

  showAccountMenuContent() {
    const menuItems = $('#header-account-info');
    const { accountContent, headerContent, animationDuration } = this;
    menuItems.on('click', function menuClick(e) {
      e.stopPropagation();

      const el = $(this);

      const isActive = el.hasClass('active');
      headerContent.slideUp(animationDuration);
      $('.header-menu__item.active').removeClass('active');
      $('.header-menu-content__item').fadeOut(animationDuration);

      if (isActive) {
        el.removeClass('active');
        return accountContent.slideUp(animationDuration);
      }

      el.addClass('active');
      return accountContent.slideDown(animationDuration);
    });
  }

  setUpMobileSearch() {
    const { animationDuration, translucentOverlayMobile } = this;
    $('#search-btn').on('click', function searchClick(e) {
      e.preventDefault();
      e.stopPropagation();

      const el = $(this);
      const isActive = el.hasClass('active');
      const searchBar = $('#search-container');
      el.toggleClass('active');

      if (isActive) {
        el.removeClass('active');
        return searchBar.animate({ width: 'hide' }, animationDuration, 'linear', () => {
          translucentOverlayMobile.hide();
        });
      }

      el.addClass('active');
      translucentOverlayMobile.show();
      return searchBar.animate({ width: 'show' }, animationDuration, 'linear', () => {
        $('#id_mobile-search-bar_search_term').focus();
      });
    });
  }

  setUpMobileHeader() {
    const { animationDuration, translucentOverlayMobile } = this;
    const objReference = this;
    // Open/Close main menu
    $('#toggle-header-menu').click(function menuClick(e) {
      e.preventDefault();
      e.stopPropagation();
      const el = $('#mobile-side-menu');

      const isOpen = el.hasClass('open');
      if (isOpen) {
        $('#mobile-side-menu').animate({ width: 'hide' }, animationDuration, 'linear', () => {
          $('.menu-container__sublist').hide();
          translucentOverlayMobile.hide();
          el.removeClass('open');
        });
      } else {
        translucentOverlayMobile.show();
        $('.menu-container__sublist').hide();
        $('#mobile-side-menu').animate({ width: 'show' }, animationDuration);
        el.addClass('open');
      }
    });

    // Open sub menu
    $('#mobile-side-menu .menu-container__list__item.has-children').click(function itemClick(e) {
      e.preventDefault();
      e.stopPropagation();
      const el = $(this);
      const contendId = el.attr('data-content-id');
      const contentEl = $(`#${contendId}`);

      const currentActiveMenuId = objReference.currentMobileSideBarActiveMenuID;

      $(`#${currentActiveMenuId}`).fadeOut(animationDuration);
      objReference.currentMobileSideBarActiveMenuID = contendId;

      contentEl.fadeIn(animationDuration);
    });

    // Go back menu
    $('.go-back-menu').click(function gobackClick(e) {
      e.preventDefault();
      e.stopPropagation();

      const el = $(this);
      const sectionToNavigateToId = el.attr('data-go-to') || DEFAULT_MOBILE_MENU_ID;
      objReference.currentMobileSideBarActiveMenuID = sectionToNavigateToId;

      $(this).parents('.menu-container__sublist').fadeOut(animationDuration);

      $(`#${sectionToNavigateToId}`).fadeIn(animationDuration);
    });
  }

  setUpReviewsMenuContent() {
    const menuItems = $('.reviews-container__menu__item .has-children');
    const { animationDuration } = this;
    menuItems.on('click', function reviewClick(e) {
      e.preventDefault();
      e.stopPropagation();

      const el = $(this);
      const contendId = el.attr('data-content-id');
      const contentEl = $(`#${contendId}`);

      menuItems.removeClass('active');
      $('.reviews-content__data').hide();

      el.addClass('active');
      return contentEl.fadeIn(animationDuration);
    });
  }

  setUpScrollEvents() {
    function addTranslucentMode() {
      const headerHeight = $('.header-container:visible').height();
      if ($(this).scrollTop() > headerHeight) {
        $('#django-header').removeClass('translucent-mode');
      } else {
        $('#django-header').addClass('translucent-mode');
      }
    }
    if (window.location.pathname === '/') {
      $('body').addClass('is-homepage');
      addTranslucentMode();
      $(window).scroll(function userScrolled() {
        addTranslucentMode();
      });
    }
  }

  initHeader() {
    this.handleClickOutside();
    this.showHeaderMenuContent();
    this.showAccountMenuContent();
    this.setUpStickyHeader();
    this.setUpMobileSearch();
    this.setUpMobileHeader();
    this.setUpReviewsMenuContent();
    this.setUpScrollEvents();
  }
}

document.addEventListener('DOMContentLoaded', function documentLoaded() {
  const header = new GoodBedHeader();
  header.initHeader();
});
